.vjsChooseVideoButtons{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;

	Button, .button{
		pointer-events: initial;
	}
}