/*@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');

$explore_blue_dark: #000B4F;
$explore_green_dark: #06380B;
$explore_green_light: #7DD197;
$explore_green_matte: #C7D9CE;
$explore_blue_light: #ABDBE9;
$explore_pink: #FFBBDA;

$button_background_color: $explore_blue_dark;
$button_background_color_hover: $explore_blue_light;
$button_border_color: $explore_blue_dark;
$button_text_color: $explore_blue_light;
$button_text_color_hover: $explore_blue_dark;

h1, h2, h3,
h4, h5, h6,
.h1, .h2, .h3,
.h4, .h5, .h6{
  font-weight: 700;
}

#root{
  min-height: 100%;
  min-height: 100vh;
  /*background: rgba(0, 0, 0, 0.3);*/
  background-color: $explore_blue_dark;
  color: $explore_blue_dark;
}

.bithero-videojs{
  position: relative;
  font-family: 'Source Sans Pro', sans-serif;

  .videoButtons,
  .headerNav,
  .callToAction{
    button, .button{
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 700;
      font-size: 0.8rem;
      padding: 0.5em 1em;
      background: $explore_green_matte;
      color: $explore_blue_dark;
      border: 2px solid $explore_green_matte;
      border-radius: 99999999px;

      &:hover{
        cursor: pointer;
        background: $explore_blue_dark;
        border-color: $explore_blue_dark;
        color: $explore_green_matte;
      }

      &.small {
        font-size: 1rem;
      }
    }

    a{
      text-decoration: none;
    }
  }

  .headerNav{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 75px;
    align-items: center;
    padding: 5px;

    /*button:last-child{
      float: right;
    }*/

    &:after{
      content: '';
      clear: both;
      display: block;
    }
  }

  .videoButtons{
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    //height: 100%;
    display: flex;
    flex-direction: row;
    grid-row-gap: 15px;
    row-gap: 15px;
    align-items: center;
    flex-wrap: wrap;

    .buttonWrapper{
      display: flex;
      justify-content: center;
      align-self: center;
      flex: 50%;
    }

    button, .button{
      text-align: left;
      min-width: 160px;

      img{
        height: 1.2em;
        width: auto;
        margin-right: 2px;
      }
    }

    
    button, .button{
      &.buttonTextAlignCenter{
        text-align: center;
      }
    }

    &.hideButtons{
      display: none;
    }

    &.showButtons{
      display: block;
    }
  }

  .callToAction{
    position: absolute;
    bottom: 1em;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    button, .button{
      margin-bottom: 20px;
      padding: 10px 20px;

      &.uppercase{
        text-transform: uppercase;
      }
    }

    a{
      display: inline-block;
      width: auto;
    }
  }

  .vjs-menu-content{
    color: white;
  }

  @media (min-width: 576px){
    .videoButtons,
    .headerNav,
    .callToAction{
      button, .button{
        font-size: 0.8rem;
      }
    }
  }

  @media (min-width: 768px){
    .headerNav{
      padding: 20px;
    }

    .callToAction{
      button, .button{
        padding: 20px 40px;
      }
    }

    .videoButtons{
      grid-row-gap: 30px;
      row-gap: 30px;

      &.treeOrLessChildren{
        flex-wrap: nowrap;
      }
    }

    .videoButtons,
    .headerNav,
    .callToAction{
      button, .button{
        font-size: 1rem;
      }
    }
  }

  @media (min-width: 992px){
    .videoButtons,
    .headerNav,
    .callToAction{
      button, .button{
        font-size: 1.2rem;
        min-width: 305px;
      }
    }
  }

  @media (min-width: 1900px){
    .videoButtons,
    .headerNav,
    .callToAction{
      button, .button{
        font-size: 2.5rem;
      }
    }
  }

  @media (min-width: 3800px){
    .videoButtons,
    .headerNav,
    .callToAction{
      button, .button{
        font-size: 5rem;
      }
    }
  }

  div.video-js{
    //border: 2px solid white;
    //-webkit-box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.6); 
    //box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.6);
  }

  .main-destination{
    background-color: $explore_green_matte;
    padding-bottom: 75px;
  }

  .main-destination-text{
    /*p{
      background: rgba(53, 57, 57, 0.6);
      padding: 10px 15px;
    }*/
  }

  .video-container{
    padding-top: 50px;
  }

  .other-destinations{
    background: white;

    .destinations-container{
      padding-top: 40px;
      padding-bottom: 50px;
      color: $explore_blue_dark;
    }
  }

  .card-deck{
    color: $explore_blue_dark;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    column-gap: 5px;

    .card{
      //width: 100%;
      border: none;
      border-radius: none;
      background: $explore_green_matte;

      .card-img{
        border-radius: none;
      }
    }
  }

  .btn{
    &.btn-green{
      background: transparent;
      color: $explore_blue_dark;
      border: 2px solid $explore_blue_dark;
      border-radius: 99999999px;
      font-weight: 700;
      padding: 10px 20px;
      text-transform: uppercase;

      &:hover{
        background: $explore_blue_dark;
        color: $explore_green_matte;
      }
    }
  }

  .video-js{
    &:hover{
      .vjs-big-play-button{
        background: $explore_green_matte;
        color: $explore_blue_dark;
      }
    }

    .vjs-control-bar{
      background: $explore_green_matte;
      color: $explore_blue_dark;
    }

    .vjs-big-play-button{
      border: 2px solid $explore_green_matte;
      color: $explore_green_matte;
      border-radius: 99999999px;
      background: transparent;

      &:hover{
        background: $explore_green_matte;

        .vjs-icon-placeholder{
          color: $explore_blue_dark;
        }
      }
    }
  }

  @media (min-width: 768px){
    .card-deck{
      .card{
        //width: 50%;
        max-width: 300px;
      }
    }
  }

  @media (min-width: 992px){
    .card-deck{
      .card{
        //width: 25%;
      }
    }
  }
}

#footer{
  width: 100%;
  padding: 50px 0;
  background: $explore_blue_dark;
  color: $explore_blue_light;

  .partner-logos{
    display: flex;
    flex-flow: row wrap;
    grid-column-gap: 25px;
    column-gap: 25px;
    align-items: center;
    justify-content: center;

    img{
      height: 55px;
      width: auto;
    }
  }
}