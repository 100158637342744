.ChooseVideoButtons{
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    pointer-events: none;
    row-gap: 50px;

	Button, .button{
		pointer-events: initial;
	}

	.headerNav{
		button{
			float: left;
		}

		button:last-of-type{
			float: right;
		}

		&:after{
			content: '';
			display: block;
			clear: both;
		}
	}

	.headerNav, .callToAction, .videoButtons{
		transition: visibility 0ms ease-in, opacity 0ms ease-in;
		visibility: hidden;
		opacity: 0;

		&.cyoav-show {
			visibility: visible;
			opacity: 1;
			transition-duration: 500ms, 500ms;
		}
	}
}