@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap);
html {
  min-height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* The image used */
  /*background-image: url("images/jamtlandstriangeln-vandring-vandrare-sjo-foto-johan-ranbrandt.jpeg");*/

  /* Full height */
  min-height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/*body:after{
  content: "";
  position: absolute;
  left: 0; right: 0;
  top: 0; bottom: 0;
  background: rgba(0,0,0,0.1);
}*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ChooseVideoButtons {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  grid-row-gap: 50px;
  row-gap: 50px;
}
.ChooseVideoButtons Button, .ChooseVideoButtons .button {
  pointer-events: initial;
}
.ChooseVideoButtons .headerNav button {
  float: left;
}
.ChooseVideoButtons .headerNav button:last-of-type {
  float: right;
}
.ChooseVideoButtons .headerNav:after {
  content: "";
  display: block;
  clear: both;
}
.ChooseVideoButtons .headerNav, .ChooseVideoButtons .callToAction, .ChooseVideoButtons .videoButtons {
  transition: visibility 0ms ease-in, opacity 0ms ease-in;
  visibility: hidden;
  opacity: 0;
}
.ChooseVideoButtons .headerNav.cyoav-show, .ChooseVideoButtons .callToAction.cyoav-show, .ChooseVideoButtons .videoButtons.cyoav-show {
  visibility: visible;
  opacity: 1;
  transition-duration: 500ms, 500ms;
}
.vjsChooseVideoButtons {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.vjsChooseVideoButtons Button, .vjsChooseVideoButtons .button {
  pointer-events: initial;
}
.NotFound {
  color: #ffffff;
}
/*@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');*/
h1, h2, h3,
h4, h5, h6,
.h1, .h2, .h3,
.h4, .h5, .h6 {
  font-weight: 700;
}

#root {
  min-height: 100%;
  min-height: 100vh;
  /*background: rgba(0, 0, 0, 0.3);*/
  background-color: #000B4F;
  color: #000B4F;
}

.bithero-videojs {
  position: relative;
  font-family: "Source Sans Pro", sans-serif;
}
.bithero-videojs .videoButtons button, .bithero-videojs .videoButtons .button,
.bithero-videojs .headerNav button,
.bithero-videojs .headerNav .button,
.bithero-videojs .callToAction button,
.bithero-videojs .callToAction .button {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  font-size: 0.8rem;
  padding: 0.5em 1em;
  background: #C7D9CE;
  color: #000B4F;
  border: 2px solid #C7D9CE;
  border-radius: 99999999px;
}
.bithero-videojs .videoButtons button:hover, .bithero-videojs .videoButtons .button:hover,
.bithero-videojs .headerNav button:hover,
.bithero-videojs .headerNav .button:hover,
.bithero-videojs .callToAction button:hover,
.bithero-videojs .callToAction .button:hover {
  cursor: pointer;
  background: #000B4F;
  border-color: #000B4F;
  color: #C7D9CE;
}
.bithero-videojs .videoButtons button.small, .bithero-videojs .videoButtons .button.small,
.bithero-videojs .headerNav button.small,
.bithero-videojs .headerNav .button.small,
.bithero-videojs .callToAction button.small,
.bithero-videojs .callToAction .button.small {
  font-size: 1rem;
}
.bithero-videojs .videoButtons a,
.bithero-videojs .headerNav a,
.bithero-videojs .callToAction a {
  text-decoration: none;
}
.bithero-videojs .headerNav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  align-items: center;
  padding: 5px;
  /*button:last-child{
    float: right;
  }*/
}
.bithero-videojs .headerNav:after {
  content: "";
  clear: both;
  display: block;
}
.bithero-videojs .videoButtons {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  flex-direction: row;
  grid-row-gap: 15px;
  grid-row-gap: 15px;
  row-gap: 15px;
  align-items: center;
  flex-wrap: wrap;
}
.bithero-videojs .videoButtons .buttonWrapper {
  display: flex;
  justify-content: center;
  align-self: center;
  flex: 50% 1;
}
.bithero-videojs .videoButtons button, .bithero-videojs .videoButtons .button {
  text-align: left;
  min-width: 160px;
}
.bithero-videojs .videoButtons button img, .bithero-videojs .videoButtons .button img {
  height: 1.2em;
  width: auto;
  margin-right: 2px;
}
.bithero-videojs .videoButtons button.buttonTextAlignCenter, .bithero-videojs .videoButtons .button.buttonTextAlignCenter {
  text-align: center;
}
.bithero-videojs .videoButtons.hideButtons {
  display: none;
}
.bithero-videojs .videoButtons.showButtons {
  display: block;
}
.bithero-videojs .callToAction {
  position: absolute;
  bottom: 1em;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
}
.bithero-videojs .callToAction button, .bithero-videojs .callToAction .button {
  margin-bottom: 20px;
  padding: 10px 20px;
}
.bithero-videojs .callToAction button.uppercase, .bithero-videojs .callToAction .button.uppercase {
  text-transform: uppercase;
}
.bithero-videojs .callToAction a {
  display: inline-block;
  width: auto;
}
.bithero-videojs .vjs-menu-content {
  color: white;
}
@media (min-width: 576px) {
  .bithero-videojs .videoButtons button, .bithero-videojs .videoButtons .button,
  .bithero-videojs .headerNav button,
  .bithero-videojs .headerNav .button,
  .bithero-videojs .callToAction button,
  .bithero-videojs .callToAction .button {
    font-size: 0.8rem;
  }
}
@media (min-width: 768px) {
  .bithero-videojs .headerNav {
    padding: 20px;
  }
  .bithero-videojs .callToAction button, .bithero-videojs .callToAction .button {
    padding: 20px 40px;
  }
  .bithero-videojs .videoButtons {
    grid-row-gap: 30px;
    grid-row-gap: 30px;
    row-gap: 30px;
  }
  .bithero-videojs .videoButtons.treeOrLessChildren {
    flex-wrap: nowrap;
  }
  .bithero-videojs .videoButtons button, .bithero-videojs .videoButtons .button,
  .bithero-videojs .headerNav button,
  .bithero-videojs .headerNav .button,
  .bithero-videojs .callToAction button,
  .bithero-videojs .callToAction .button {
    font-size: 1rem;
  }
}
@media (min-width: 992px) {
  .bithero-videojs .videoButtons button, .bithero-videojs .videoButtons .button,
  .bithero-videojs .headerNav button,
  .bithero-videojs .headerNav .button,
  .bithero-videojs .callToAction button,
  .bithero-videojs .callToAction .button {
    font-size: 1.2rem;
    min-width: 305px;
  }
}
@media (min-width: 1900px) {
  .bithero-videojs .videoButtons button, .bithero-videojs .videoButtons .button,
  .bithero-videojs .headerNav button,
  .bithero-videojs .headerNav .button,
  .bithero-videojs .callToAction button,
  .bithero-videojs .callToAction .button {
    font-size: 2.5rem;
  }
}
@media (min-width: 3800px) {
  .bithero-videojs .videoButtons button, .bithero-videojs .videoButtons .button,
  .bithero-videojs .headerNav button,
  .bithero-videojs .headerNav .button,
  .bithero-videojs .callToAction button,
  .bithero-videojs .callToAction .button {
    font-size: 5rem;
  }
}
.bithero-videojs .main-destination {
  background-color: #C7D9CE;
  padding-bottom: 75px;
}
.bithero-videojs .main-destination-text {
  /*p{
    background: rgba(53, 57, 57, 0.6);
    padding: 10px 15px;
  }*/
}
.bithero-videojs .video-container {
  padding-top: 50px;
}
.bithero-videojs .other-destinations {
  background: white;
}
.bithero-videojs .other-destinations .destinations-container {
  padding-top: 40px;
  padding-bottom: 50px;
  color: #000B4F;
}
.bithero-videojs .card-deck {
  color: #000B4F;
  display: flex;
  flex-flow: row wrap;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
}
.bithero-videojs .card-deck .card {
  border: none;
  border-radius: none;
  background: #C7D9CE;
}
.bithero-videojs .card-deck .card .card-img {
  border-radius: none;
}
.bithero-videojs .btn.btn-green {
  background: transparent;
  color: #000B4F;
  border: 2px solid #000B4F;
  border-radius: 99999999px;
  font-weight: 700;
  padding: 10px 20px;
  text-transform: uppercase;
}
.bithero-videojs .btn.btn-green:hover {
  background: #000B4F;
  color: #C7D9CE;
}
.bithero-videojs .video-js:hover .vjs-big-play-button {
  background: #C7D9CE;
  color: #000B4F;
}
.bithero-videojs .video-js .vjs-control-bar {
  background: #C7D9CE;
  color: #000B4F;
}
.bithero-videojs .video-js .vjs-big-play-button {
  border: 2px solid #C7D9CE;
  color: #C7D9CE;
  border-radius: 99999999px;
  background: transparent;
}
.bithero-videojs .video-js .vjs-big-play-button:hover {
  background: #C7D9CE;
}
.bithero-videojs .video-js .vjs-big-play-button:hover .vjs-icon-placeholder {
  color: #000B4F;
}
@media (min-width: 768px) {
  .bithero-videojs .card-deck .card {
    max-width: 300px;
  }
}
#footer {
  width: 100%;
  padding: 50px 0;
  background: #000B4F;
  color: #ABDBE9;
}
#footer .partner-logos {
  display: flex;
  flex-flow: row wrap;
  grid-column-gap: 25px;
  grid-column-gap: 25px;
  -webkit-column-gap: 25px;
          column-gap: 25px;
  align-items: center;
  justify-content: center;
}
#footer .partner-logos img {
  height: 55px;
  width: auto;
}
